<template>
    <div class="pages">
        <pageHeader></pageHeader>
        <div class="box">
            <div class="main">
                <acountNav></acountNav>
                <div class="acount-box">
                    <div class="acount-header">
                        <div class="acount-header-item" :class="index === headerIdx ? 'acount-header-item-active' : ''"
                            v-for="(item, index) in headerLists" :key="index
                                ">{{ item }}</div>
                    </div>
                    <div class="acount-main">
                        <div class="article-lists" v-if="collectionLists.length > 0">
                            <div class="article-item" v-for="(item, index) in collectionLists" :key="index"
                                @click="goDetails(index)">
                                <div class="time-box">
                                    <span class="time">{{ item.payTime.split('T')[0] + ' ' + item.payTime.split('T')[1]
                                    }}</span>
                                    <span class="state">{{ item.state === 0 ? '待支付' : item.state === 1 ? '已完成' : item.state ===
                                        2 ? '已关闭' : '已退款' }}</span>
                                </div>
                                <div class="dingyue-box" v-if="item.type === 1">
                                    <img src="../../static/images/dingyue-bg.png">
                                    <div class="dingyue-box-right">
                                        <span class="dingyue-title">{{ item.packageParam }}</span>
                                        <div class="footer-box" style="margin-top: 70px;">
                                            <div class="footer-box-left">
                                                <span class="kuang">文章</span>
                                                <span class="num">数量1</span>
                                            </div>
                                            <span class="price"><span>￥</span>{{ item.amount }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="title-box" v-if="item.type === 0">
                                    <span class="column">#{{ item.detail.tags[0] }}</span>
                                    <span class="title">{{ item.detail.categoryName }}</span>
                                </div>
                                <span class="content" v-if="item.type === 0">{{ item.detail.description }}</span>
                                <div class="footer-box" v-if="item.type === 0">
                                    <div class="footer-box-left">
                                        <span class="kuang">文章</span>
                                        <span class="num">数量1</span>
                                    </div>
                                    <span class="price"><span>￥</span>{{ item.amount }}</span>
                                </div>
                            </div>
                            <noData v-if="collectionLists.length === 0" title="暂无数据"></noData>
                            <getMore @getMore="getMore" :state="getMoreState"></getMore>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pageFooter></pageFooter>
    </div>
</template>

<script>
import noData from '@/components/noData.vue'
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
import acountNav from '@/components/acountNav.vue'
import getMore from '@/components/getMore.vue'
import { getMyOrder } from '@/api/pay.js'
export default {
    name: "order",
    components: {
        pageHeader,
        pageFooter,
        acountNav,
        getMore,
        noData
    },
    data() {
        return {
            headerLists: ['全部订单'],
            headerIdx: 0,
            collectionLists: [],
            page: 0,//页码
            pageSize: 10,//页码大小
            orderNo: '',//订单号
            getMoreState: 'getMore',
            total:null
        }
    },
    created() {
        this.getMyOrder()
    },
    methods: {
         /**
         * 加载更多
         */
         getMore() {
            this.getMoreState = 'loading'
            setTimeout(() => {
                this.getMyOrder()
            }, 800)
        },
        /**
         * 切换nav
         */
        // tapHeaderNav(index) {
        //     this.headerIdx = index
        //     this.collectionLists = []
        //     this.page = 0
        //     this.total = null
        //     this.getMyOrder()
        // },
        /**
         * 获取我的订单
         */
        getMyOrder() {
            if (this.total === this.collectionLists.length){
                this.getMoreState = 'noMore'
                return
            }
            this.page++
            let data = {
                page: this.page,
                pageSize: this.pageSize,
                filter: {}
            }
            if (this.headerIdx !== 0) {
                data.filter = {
                    states: this.headerIdx
                }
            }
            getMyOrder(data).then(res => {
                this.collectionLists = this.collectionLists.concat(res.items)
                this.total = res.totalCount
                if(res.items.length < this.pageSize){
                    this.getMoreState = 'noMore'
                }else{
                    this.getMoreState = 'getMore'
                }
            })
        }
    }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #F7F7F8;
}

.main {
    width: 1200px;
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.acount-box {
    width: 888px;
    background-color: #ffffff;
    min-height: calc(100vh - 120px - 16px - 28px);
    margin-top: 16px;
    margin-bottom: 28px;
}

.acount-header {
    width: 888px;
    border-bottom: 1px solid #E8E8E8;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 18px;
}

.acount-header-item {
    height: 60px;
    margin-right: 70px;
    color: #000000;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.acount-header-item-active {
    position: relative;
    color: #DD0722;
}

.acount-header-item-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #DD0722;
}

.acount-main {
    width: 888px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.article-lists {
    width: 888px;
    background-color: #ffffff;
}

.article-item {
    width: 848px;
    padding: 20px 0;
    margin: 0 20px;
    border-bottom: 1px solid #CDD2D9;
    cursor: pointer;
}

.article-item:last-child {
    border: none;
}

.time-box {
    width: 848px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.time-box .time {
    color: #595959;
    font-size: 16px;
}

.article-item .title-box {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.article-item .title-box span {
    font-size: 18px;
    margin-right: 8px;
    font-weight: bold;
}

.article-item .title-box .column {
    color: #4181B8;
}

.article-item .title-box .title {
    color: #06121E;
}



.article-item .content {
    display: block;
    margin-top: 16px;
    font-size: 16px;
    color: #06121E;
}

.article-item .footer-box {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dingyue-box{
    width: 848px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 18px;
}
.dingyue-box img{
    width: 208px;
    height: 118px;
    margin-right: 20px;
}
.dingyue-box .dingyue-box-right{
    flex: 1;
}
.dingyue-box .dingyue-box-right .dingyue-title{
    color: #06121E;
    font-size: 18px;
    font-weight: bold;
}

.footer-box-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.footer-box-left .kuang {
    border: 1px solid #DD0722;
    color: #DD0722;
    font-size: 12px;
    width: 32px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
}

.footer-box-left .num {
    color: #BDC4CE;
    font-size: 12px;
}

.article-item .footer-box .price {
    color: #E2450A;
    font-size: 18px;
}

.article-item .footer-box .price span {
    font-size: 11px;
    margin-right: 4px;
}</style>
